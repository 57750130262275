/* Specify box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove internal indents */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove external indents */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove standard stylization for all ul and li that have a class */
ul[class],
ol[class] {
    list-style: none;
}

/* Elements a that do not have a class are reset to default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Simplify image handling */
img {
    max-width: 100%;
    display: block;
}

/* Specify a clear periodicity in the data flow of article */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="tel"]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

/* Remove all animations and transitions for people who prefer not to use them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
