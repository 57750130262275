@tailwind base;
@tailwind components;
@tailwind utilities;

@page {
    size: auto;
    margin: 10mm;
}

.page {
    page-break-before: always;
}

table {
    width: 100%;
}

td,
th {
    border: 1px solid #444444 !important;
    padding: 6px;
}

th {
    background-color: rgb(228, 228, 228);
}

td {
    background-color: white;
}

td div {
    display: flex;
    flex-direction: column;
}

.small_text {
    font-size: 0.8em;
    line-height: 1.2rem;
}

.small_text_1 {
    font-size: 0.4em;
}

.page_id {
    font-size: 7px;
}

@media print {
    .print_hide {
        display: none;
    }
}

@media print {
    .print_display_end {
        justify-content: flex-end;
    }
}
